<template>
  <div class="mt-down-header">
    <!-- breadcrumb -->
    <b-breadcrumb :items="items" class="my-bread">位置：</b-breadcrumb>
    <!-- breadcrumb end -->

    <div class="main-layout">
      <!-- list banner -->
      <div class="list-banner">
        <img src="@/assets/img/bg-dss.png" alt>
        <!-- <div class="content flex-center-column">
          <h3>想要一份量身定制的策略吗?</h3>
          <p>那么你还在等什么?点击下方的按钮立即定制吧</p>
        </div> -->
      </div>
      <!-- list banner end-->

      <!-- items list -->
      <div class="dss-list mt-30 mb-30">
        <div v-for="(item,index) in cardByAnswer" :key="index" class="item flex-common">
          <img :src="&quot;@/assets/img/product-&quot;+item.productid+ &quot;.png&quot;" alt>
          <div class="desc flex-between-column">
            <div class>
              <h1>{{ item.description }}</h1>
              <p>{{ item.something }}</p>
            </div>
            <div class="flex-common">
              <button class="try">体验</button>
              <button class="case">案例</button>
            </div>
          </div>
        </div>
      </div>
      <!-- items list end -->
    </div>
  </div>
</template>

<script>
import '../../assets/style/common.scss'
export default {
  data() {
    return {
      // 面包屑导航
      items: [
        {
          text: '主页',
          href: '/#home'
        },
        {
          text: '定制',
          active: true
        }
      ],

      cards: [],

      cardByAnswer: []
    }
  },

  mounted() {
    this.fetchDssData()
  },

  methods: {
    fetchDssData: function() {
      const api = 'api/office_web/dss_content/'
      this.http.get(api).then(res => {
        this.cards = res.data.cards
        // console.log('this.cards:', this.cards);
        this.filterSelectedQue()
      })
    },

    filterSelectedQue: function() {
      const paramObj = JSON.parse(localStorage.getItem('que_selected_by_user'))
      console.log('paramObj', paramObj)
      const suggestioned = paramObj.que_selected.filter(item => {
        return item.selected === true
      }).map(item => {
        return item.suggestions[0].name
      })
      console.log('suggestioned', suggestioned)
      this.cardByAnswer = this.cards.filter(item => {
        return suggestioned.includes(item.name)
      })
      console.log('this.cardByAnswer', this.cardByAnswer)
    }
  }
}
</script>

<style lang="scss">
.mt-down-header {
  margin-top: 66px;
}

.my-bread {
  padding-right: 105px;
  padding-left: 105px;
  font-size: 16px;
  font-family: PingFang-SC-Regular;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  background: rgba(242, 242, 242, 1);
}

.list-banner {
  // padding: 20px;
  font-family: PingFang-SC-Regular;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  background-size: 100% 100%;
  position: relative;
  letter-spacing: 2px;

  img {
    width: 100%;
    height: 100%;
  }
  h3 {
    font-size: 36px;
  }
  p {
    font-size: 24px;
  }

  div.content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.main-layout {
  padding: 0 105px;
}

// dss-list
.dss-list {
  .item {
    border: 2px solid #fff;
    padding: 10px;
    margin-bottom: 20px;

    img {
      width: 320px;
      min-height: 220px;
      border-radius: 5px;
      // vertical-align: 0;
    }

    div.desc {
      padding: 30px;
      letter-spacing: 2px;

      h1 {
        font-size: 26px;
        font-weight: 0;
        height: 30px;
        overflow: hidden;
      }

      p {
        height: 76px;
        overflow: hidden;
      }

      button {
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #fff;
        padding: 5px 30px;
        margin-right: 20px;
      }

      button.try {
        background: rgba(255, 51, 51, 1);
      }
      button.try:hover {
        background: #fff;
        border: 1px solid rgba(255, 51, 51, 1);
        color: rgba(255, 51, 51, 1);
      }

      button.case {
        background: rgba(14, 20, 52);
      }
      button.case:hover {
        background: #fff;
        border: 1px solid rgba(14, 20, 52);
        color: rgb(14, 20, 52);
      }
    }
  }

  .item:hover {
    box-sizing: border-box;
    border: 2px solid rgba(255, 51, 51, 1);
  }
}
</style>

